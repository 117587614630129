<template>
	<div class="tooltip" :class="class" :data-tip="tip">
		<slot />
	</div>
</template>

<script lang="ts">
export default {
	props: {
		class: String | null,
		tip: String
	}
}
</script>
