<template>
	<li>
		<NuxtLink to="/" target="_top">Início</NuxtLink>
	</li>
	<li>
		<NuxtLink @click="useLPNavigate('about')">Sobre</NuxtLink>
	</li>
	<li>
		<NuxtLink @click="useLPNavigate('massages')">Massagens</NuxtLink>
	</li>
	<li>
		<NuxtLink @click="useLPNavigate('contact')">Contato</NuxtLink>
	</li>
</template>
