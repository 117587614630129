<template>
	<div class="navbar bg-base-300 sticky top-0 z-10 -mb-16" id="home">
		<div class="navbar-start">
			<div class="dropdown">
				<label tabindex="0" class="btn btn-ghost lg:hidden">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
						stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
					</svg>
				</label>
				<ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
					<MenuItems />
				</ul>
			</div>
			<NuxtLink class="btn btn-ghost bg-white hover:bg-white normal-case text-xl" id="logo" to="/"
				:aria-label="appName" target="_top">
				<nuxt-img src="/image/logo/text.png" class="!h-full" quality="100" :alt="appName" />
			</NuxtLink>
		</div>
		<div class="navbar-center hidden lg:flex">
			<ul class="menu menu-horizontal px-1">
				<MenuItems />
			</ul>
		</div>
		<div class="navbar-end">
			<NuxtLink class="btn gap-2" :to="appWhatsAppURL" target="_blank">
				<i class="fa-brands fa-whatsapp"></i>
				<span class="hidden md:block">WhatsApp</span>
			</NuxtLink>
		</div>
	</div>
</template>
